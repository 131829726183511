<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">

    <MainForm 
        :form="form"
        :error="error"
        :purpose="purpose"
        :mainSentenceShow="false"
    />
      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/good-conduct-certificate')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
  },

  components: {
    MainForm,
  },

  data() {
    return {
      // Error
      error: {
        citizen_id: "",
        name: "",
        id_card_number: "",
        gender: "",
        birt_place: "",
        birt_date: "",
        title: "",
        number: "",
        hamlet_id: "",
        purpose: "",
      },
    };
  },

  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/good-conduct-certificate");
      }
    },
  },
};
</script>

<style>
</style>